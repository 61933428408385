export default function() {
  if (navigator.share) {
    for (let share of document.querySelectorAll('.js-share-btn')) {
      share.addEventListener('click', event => {
        event.preventDefault();
        const shareButton = event.target.closest('.js-share-btn');
        navigator.share({
          title: shareButton.getAttribute('data-title'),
          url: shareButton.getAttribute('data-url'),
        });
      });
    }
  }
}
